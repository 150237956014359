@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap";
:root {
  --primary-color: #0a2239;
  --secondary-color: #1d84b5;
  --black-color: #000;
  --white-color: #fff;
  --grey-color: #f7f7f7;
  --light-grey-color: #f2f2f2;
  --dark-grey-color: #cfcfcf;
  --spacing-1: .2rem;
  --spacing-2: .4rem;
  --spacing-3: .6rem;
  --spacing-4: 1rem;
  --spacing-5: 1.6rem;
  --spacing-6: 2.4rem;
  --spacing-7: 3.2rem;
  --spacing-8: 4.8rem;
  --spacing-9: 6.4rem;
  --spacing-10: 8rem;
  --spacing-11: 9.6rem;
  --spacing-12: 12.8rem;
  --font-10: 1rem;
  --font-12: 1.2rem;
  --font-14: 1.4rem;
  --font-16: 1.6rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-24: 2.4rem;
  --font-30: 3rem;
  --font-36: 3.6rem;
  --font-44: 4.4rem;
  --font-52: 5.2rem;
  --font-62: 6.2rem;
  --font-74: 7.4rem;
  --font-86: 8.6rem;
  --font-98: 9.8rem;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --shadow-xxsmall: 0 .8rem 1.6rem #0000001a;
  --shadow-xsmall: 0 1.2rem 2.4rem #0000001a;
  --shadow-small: 0 2.4rem 4.8rem #0000001a;
  --shadow-medium: 0 4.8rem 9.6rem #0000001a;
  --shadow-large: 0 9.6rem 16.8rem #0000001a;
  --shadow-xlarge: 0 16.8rem 25.6rem #0000001a;
  --shadow-xxlarge: 0 25.6rem 37.6rem #0000001a;
}

.material-icons {
  color: var(--white-color);
  font-size: 3.6rem;
}

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #a5cee1;
  min-height: 100vh;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  position: relative;
}

.wrapper {
  flex-direction: column;
  height: 100vh;
  padding: 0 3.2rem;
  display: flex;
  position: relative;
}

.header {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 4.3rem;
  display: flex;
}

.logo:hover, .logo:active, .logo:link, .logo:visited {
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.logo--icon {
  color: var(--secondary-color);
  font-size: 7.4rem;
  font-weight: 900;
}

.logo--text {
  color: var(--primary-color);
  font-size: 3.6rem;
  font-weight: 700;
  transform: rotate(-10deg);
}

.primary-heading, .secondary-heading, .tertiary-heading {
  font-weight: var(--font-weight-800);
  letter-spacing: .1rem;
  margin-bottom: 3.2rem;
}

.primary-heading {
  color: var(--primary-color);
  font-size: 4.8rem;
  line-height: 1.2;
}

.primary-heading span {
  color: var(--secondary-color);
}

.btn {
  color: var(--primary-color);
  font-size: var(--font-16);
  font-weight: var(--font-weight-600);
  padding: var(--spacing-4) var(--spacing-6);
  cursor: pointer;
  background-color: #0000;
  border: none;
  transition: all .5s;
}

.btn--sign-up {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  background-color: #0000;
}

.btn--sign-up:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btn--log-in {
  color: var(--primary-color);
  font-weight: var(--font-weight-600);
  text-decoration: none;
  transition: all 1s;
}

.btn--log-in:hover {
  text-decoration: underline;
}

.btn--log-out {
  color: var(--primary-color);
  font-size: var(--font-30);
  font-weight: var(--font-weight-600);
  cursor: pointer;
  transition: all 1s;
}

.btn--submit {
  background-color: var(--white-color);
  color: var(--primary-color);
  font-size: var(--font-16);
  font-weight: var(--font-weight-600);
  padding: var(--spacing-4) var(--spacing-6);
  border-radius: var(--spacing-8);
  cursor: pointer;
  border: none;
  text-decoration: none;
  transition: all .5s;
}

.btn--submit:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.btn--to-add {
  color: var(--white-color);
  background-color: var(--primary-color);
  transform-origin: center;
  z-index: 5;
  border: none;
  border-radius: 50%;
  width: 6.3rem;
  height: 6.3rem;
  transition: all .8s;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.btn--to-add:hover {
  background-color: var(--primary-tint-color);
  transform: rotate(90deg)scale(1.1);
  box-shadow: 0 0 20px #0003;
}

.overlay {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.81eae96e.css.map */
