@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

:root {
    /* Colors */
    --primary-color: #0A2239;
    --secondary-color: #1D84B5;
    --black-color: #000;
    --white-color: #fff;
    --grey-color: #f7f7f7;
    --light-grey-color: #f2f2f2;
    --dark-grey-color: #cfcfcf;

    /* Spacing */
    --spacing-1: 0.2rem; /* Equivalent to 2px (10px * 0.2) */
    --spacing-2: 0.4rem; /* Equivalent to 4px (10px * 0.4) */
    --spacing-3: 0.6rem; /* Equivalent to 6px (10px * 0.6) */
    --spacing-4: 1rem; /* Equivalent to 10px (10px * 1) */
    --spacing-5: 1.6rem; /* Equivalent to 16px (10px * 1.6) */
    --spacing-6: 2.4rem; /* Equivalent to 24px (10px * 2.4) */
    --spacing-7: 3.2rem; /* Equivalent to 32px (10px * 3.2) */
    --spacing-8: 4.8rem; /* Equivalent to 48px (10px * 4.8) */
    --spacing-9: 6.4rem; /* Equivalent to 64px (10px * 6.4) */
    --spacing-10: 8rem; /* Equivalent to 80px (10px * 8) */
    --spacing-11: 9.6rem; /* Equivalent to 96px (10px * 9.6) */
    --spacing-12: 12.8rem; /* Equivalent to 128px (10px * 12.8) */

    /* Font size */
    --font-10: 1rem; /* Equivalent to 10px (10px * 1) */
    --font-12: 1.2rem; /* Equivalent to 12px (10px * 1.2) */
    --font-14: 1.4rem; /* Equivalent to 14px (10px * 1.4) */
    --font-16: 1.6rem; /* Equivalent to 16px (10px * 1.6) */
    --font-18: 1.8rem; /* Equivalent to 18px (10px * 1.8) */
    --font-20: 2rem; /* Equivalent to 20px (10px * 2) */
    --font-24: 2.4rem; /* Equivalent to 24px (10px * 2.4) */
    --font-30: 3rem; /* Equivalent to 30px (10px * 3) */
    --font-36: 3.6rem; /* Equivalent to 36px (10px * 3.6) */
    --font-44: 4.4rem; /* Equivalent to 44px (10px * 4.4) */
    --font-52: 5.2rem; /* Equivalent to 52px (10px * 5.2) */
    --font-62: 6.2rem; /* Equivalent to 62px (10px * 6.2) */
    --font-74: 7.4rem; /* Equivalent to 74px (10px * 7.4) */
    --font-86: 8.6rem; /* Equivalent to 86px (10px * 8.6) */
    --font-98: 9.8rem; /* Equivalent to 98px (10px * 9.8) */

    /* Font weight */
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;

    /* Shadows */
    --shadow-xxsmall: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    --shadow-xsmall: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
    --shadow-small: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.1);
    --shadow-medium: 0 4.8rem 9.6rem rgba(0, 0, 0, 0.1);
    --shadow-large: 0 9.6rem 16.8rem rgba(0, 0, 0, 0.1);
    --shadow-xlarge: 0 16.8rem 25.6rem rgba(0, 0, 0, 0.1);
    --shadow-xxlarge: 0 25.6rem 37.6rem rgba(0, 0, 0, 0.1);
}

.material-icons {
    font-size: 3.6rem;
    color: var(--white-color);
}


html {
    font-size: 62.5%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    min-height: 100vh;
    background-color: #a5cee1;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 0; /* Remove default body margin */

    /*overflow: hidden;*/
}

.wrapper {
    position: relative;
    display: flex; /* Make the wrapper a flex container */
    flex-direction: column; /* Arrange children vertically */
    height: 100vh; /* Take up full viewport height */
    padding: 0 3.2rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4.3rem;
    width: 100%; /* Ensure the header takes up 100% width of the wrapper */
    flex-wrap: wrap;

}


/*  Logo */
.logo:hover,
.logo:active,
.logo:link,
.logo:visited {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo--icon {
    color: var(--secondary-color);
    font-size: 7.4rem;
    font-weight: 900;
}

.logo--text {
    font-size: 3.6rem;
    font-weight: 700;
    transform: rotate(-10deg);
    color: var(--primary-color);

}

.primary-heading,
.secondary-heading,
.tertiary-heading {
    font-weight: var(--font-weight-800);
    margin-bottom: 3.2rem;
    letter-spacing: 0.1rem;
}

.primary-heading {
    font-size: 4.8rem;
    line-height: 1.2;
    color: var(--primary-color);
}

.primary-heading span {
    color: var(--secondary-color);
}


.btn {
    color: var(--primary-color);
    font-size: var(--font-16);
    font-weight: var(--font-weight-600);
    padding: var(--spacing-4) var(--spacing-6);
    border: none;
    cursor: pointer;
    transition: all 0.5s;
    background-color: transparent;
}

.btn--sign-up {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    background-color: transparent;
}

.btn--sign-up:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.btn--log-in {
    color: var(--primary-color);
    font-weight: var(--font-weight-600);
    text-decoration: none;
    transition: all 1s;
}

.btn--log-in:hover {
    text-decoration: underline;
}

.btn--log-out {
    color: var(--primary-color);
    font-size: var(--font-30);
    font-weight: var(--font-weight-600);
    cursor: pointer;
    transition: all 1s;
}

.btn--submit {
    background-color: var(--white-color);
    color: var(--primary-color);
    font-size: var(--font-16);
    font-weight: var(--font-weight-600);
    padding: var(--spacing-4) var(--spacing-6);
    border-radius: var(--spacing-8);
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: all 0.5s;
}

.btn--submit:hover {
    color: var(--white-color);
    background-color: var(--primary-color);

}

.btn--to-add {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    color: var(--white-color);
    background-color: var(--primary-color);

    height: 6.3rem;
    width: 6.3rem;
    border: none;
    border-radius: 50%;
    transition: all 0.8s ease;
    transform-origin: center; /* Set the transformation origin to the center */
    z-index: 5;
}

.btn--to-add:hover {
    background-color: var(--primary-tint-color);
    transform: rotate(90deg) scale(1.1); /* Rotate the button by 45 degrees and scale up by 10% on hover */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); /* Add a subtle box shadow on hover */
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}